import axiosInstance from './axiosInstance';
import retryRequest from './retryRequest';

const getCoordinatesByURI = async (uri) => {
  try {
    const response = await retryRequest(() =>
      axiosInstance.get('/coordinates', { params: { uri } })
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching coordinates:', error);
    return null;
  }
};

const getAddressByCoordinates = async (lat, lon) => {
  try {
    const response = await retryRequest(() =>
      axiosInstance.get('/address', { params: { lat, lon } })
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching address:', error);
    return null;
  }
};

export default getCoordinatesByURI;
export { getAddressByCoordinates };