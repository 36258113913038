import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import axiosInstance from '../../utils/axiosInstance';


const initialState = {
  currentOrders: [],
  searchQuery: '',
  filteredOrders: [],
  status: 'idle',
  error: null,
};

export const fetchCurrentOrders = createAsyncThunk('orders/fetchCurrentOrders', async () => {
  const response = await axiosInstance.get('/current-orders', {
    withCredentials: true
  });
  return response.data;
});

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
      state.filteredOrders = state.currentOrders.filter(order => 
        order.address_from.toLowerCase().includes(action.payload.toLowerCase()) ||
        order.address_to.toLowerCase().includes(action.payload.toLowerCase()) ||
        order.phone_number.includes(action.payload)
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCurrentOrders.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentOrders = Array.isArray(action.payload) ? action.payload : [];
        state.filteredOrders = state.currentOrders;
      })
      .addCase(fetchCurrentOrders.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setSearchQuery } = ordersSlice.actions;
export const selectCurrentOrders = (state) => state.currentOrders.filteredOrders;

const persistConfig = {
  key: 'currentOrders',
  storage,
};

const persistedOrdersReducer = persistReducer(persistConfig, ordersSlice.reducer);

export default persistedOrdersReducer;