import axiosInstance from "./axiosInstance";
import retryRequest from "./retryRequest";

const fetchSuggestions = async (text) => {
  try {
    const response = await retryRequest(() =>
      axiosInstance.get('/geosuggest', { params: { text } })
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching suggestions:', error);
    return [];
  }
};

export default fetchSuggestions;