import { logout } from '../redux/slices/authSlice';
import { store } from '../redux/store';
import axiosInstance from './axiosInstance';
import retryRequest from './retryRequest';

export const refreshAccessToken = async () => {
  try {
    const response = await retryRequest(() =>
      axiosInstance.post('/refresh', {}, { withCredentials: true })
    );
    
    if (response.data.accessToken) {
      localStorage.setItem('accessToken', response.data.accessToken);
      return response.data.accessToken;
    }
  } catch (error) {
    console.error('Failed to refresh token:', error);
    store.dispatch(logout());
    return null;
  }
};
